import React, { useState, useEffect } from 'react';
import AniLink from "gatsby-plugin-transition-link/AniLink"

import bootstrap from "./bootstrap.module.css"
import Tile from "./tile"

const calcDisplayCount = () => {
    const pageWidth = typeof window === "object" ? window.innerWidth : 9999;

    if (pageWidth < 576) {
        return 1;
    }

    if (pageWidth < 1200) {
        return 2;
    }

    return 4;
}

const SummaryYear = ({ posts }) => {
    const [activeIndex, setActiveIndex] = useState(0);
    const [displayCount, setDisplayCount] = useState(() => calcDisplayCount());

    const prevImage = () => {
        setActiveIndex(Math.max(activeIndex - 1, 0));
    };

    const nextImage = () => {
        setActiveIndex(Math.min(activeIndex + 1, posts.length - displayCount));
    };

    useEffect(() => {
        const updateDisplayCount = () => {
            setDisplayCount(calcDisplayCount());
        };

        updateDisplayCount();

        window.addEventListener('resize', updateDisplayCount, { passive: true });

        return () => {
            window.removeEventListener('resize', updateDisplayCount, { passive: true });
        }
    }, [])

    return (
        <div className={["summary-year", bootstrap.p3, bootstrap.mb5, bootstrap.pMd4, bootstrap.mlLg3].join(' ')}>
            <div className={[bootstrap.row, bootstrap.alignItemsCenter, bootstrap.mb2, bootstrap.mbXl3].join(' ')}>
                <div className={bootstrap.colAuto}>
                    <div style={{ padding: '3px', backgroundColor: '#fff' }}>
                        <img width="43" height="43" src="/assets/ey.svg" alt="" />
                    </div>
                </div>

                <h2 className={[bootstrap.h1, bootstrap.textWhite, bootstrap.col, bootstrap.mb0, bootstrap.pl0].join(' ')}>Sztuczna inteligencja i&nbsp;regulowanie innowacji</h2>
            </div>

            <div className={['summary-year-row', bootstrap.px2, bootstrap.mb4, bootstrap.mbMd5].join(' ')}>
                {posts.length > displayCount && activeIndex > 0 && <button onClick={prevImage}>▶</button>}
                
                {posts.slice(activeIndex, activeIndex + displayCount).map((post, i) => {
                    const post_excerpt = post.post_excerpt.length > 175 
                        ? post.post_excerpt.slice(0, 175).trim().padEnd(178, '.')
                        : post.post_excerpt;
                    
                    return  (
                        <div key={i} className={[bootstrap.col12, bootstrap.colSm6, bootstrap.colXl3, bootstrap.px2, bootstrap.mt3, bootstrap.mtLg4, bootstrap.mtXl3].join(' ')}>
                            <Tile id={i} node={{ ...post, post_excerpt }} showAuthor />
                        </div>
                    );
                })}

                {posts.length > displayCount && activeIndex < posts.length - displayCount && <button onClick={nextImage}>▶</button>}
            </div>

            <AniLink cover to="/aktualnosci/podsumowania-i-prognozy-2024/" className="btn-more">WIĘCEJ ARTYKUŁÓW</AniLink>
        </div>
    );
};

export default SummaryYear;