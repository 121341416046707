import React from "react"
import Img from 'gatsby-image';
import bootstrap from "../components/bootstrap.module.css"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import Tags from "./tags.js"
import Excerpt from "./excerpt.js"
import FormatDate from "../utils/format-date.js"
import PostThumbnail from "./thumbnail";

const Tile = ({ node, className, id, showAuthor, hideThumbnail, hideExcerpt }) => {
    
    return (
        <div key={id} className={['card',bootstrap.h100,className].join(' ')}> 
            { !hideThumbnail && <AniLink fade to={`/${node.post_name}/`} className={bootstrap.dBlock+' '+bootstrap.mb3}><PostThumbnail image={node.mysqlImage} /></AniLink> }
            <div className={[bootstrap.px4, !hideThumbnail ? bootstrap.pt2 : bootstrap.pt4,bootstrap.pb4].join(' ')}>       
                <div className={bootstrap.small + ' meta ' + bootstrap.mb2}>{FormatDate(node.post_date)}</div>
                <AniLink fade to={`/${node.post_name}/`} className={bootstrap.mb2 + ( !hideThumbnail ? ' h4 ' + bootstrap.h4 : ' h3 ' + bootstrap.h3) + ' ' + bootstrap.dBlock}>{node.post_title}</AniLink>
                {!hideExcerpt && <Excerpt node={node} className={[bootstrap.py2,bootstrap.textMuted].join(' ')} />}
                <Tags tags={node.postterms} />

                {showAuthor && node.author.mysqlImage && (
                    <div className={[bootstrap.row, bootstrap.alignItemsCenter, bootstrap.mt3].join(' ')}>
                        <div className={['author', bootstrap.colAuto, bootstrap.pr0].join(' ')}>
                            <Img fluid={node.author.mysqlImage.childImageSharp.fluid} style={{ width: '30px' }} />
                        </div>

                        <div className={[bootstrap.col, bootstrap.pl2].join(' ')}>
                            <strong><AniLink fade to={"/redaktor/"+node.author.user_nicename+"/"}>{node.author.display_name}</AniLink></strong><br />
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
  }

  
  export default Tile