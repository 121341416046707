import React from "react"
import bootstrap from "../components/bootstrap.module.css"
import FormatDate from "../utils/format-date.js"

const Calendar = ({ events }) => {
    
    const date = new Date();
    const firstDay = new Date(date.getFullYear(), date.getMonth(), 1).getDay();
    const lastDay = new Date(date.getFullYear(), date.getMonth()+1, 0).getDate();
    const zeroPad = (num, places) => String(num).padStart(places, '0')

    const eventsCount = {};
    events.filter( ({node}, i) => (new Date(Date.parse(node.date))).getMonth() === (new Date()).getMonth() && (new Date(Date.parse(node.date))).getYear() === (new Date()).getYear() ).map(({ node }) => (
        eventsCount[(new Date(Date.parse(node.date))).getDate()] = eventsCount[(new Date(Date.parse(node.date))).getDate()] ? eventsCount[(new Date(Date.parse(node.date))).getDate()]++ : 1
    ))

    let week = 0;

    let days = firstDay-1 > 0 ? [[...Array(firstDay-1)]] : [[]]
    for(let i=1; i<=lastDay; i++) {
        days[week].push(new Date(date.getFullYear(), date.getMonth(), i));
        if(days[week].length === 7) {
            week++;
            days[week] = [];
        }
    }
    let i = 1;
    while(days[week].length < 7) {
        days[week].push(null);
    }
     
    
    return (
    <div>
    

        <table className={'calendar-events'}> 
            {days.map((rows, week) => (
                <tr>
                    {rows.map((day) => (
                    <td className={[day && eventsCount[day.getDate()] ? 'has-events' : ''].join(' ')}>
                        <div className={[bootstrap.small,bootstrap.textUppercase,bootstrap.mb1].join(' ')}>{ day && eventsCount[day.getDate()] && (eventsCount[day.getDate()] + ` event`) }</div>
                        <span>{ day && zeroPad(day.getDate(),2) }</span>
                    </td>
                    ))}
                </tr>
            ))}
        </table>
    </div>
    )
    }

  
  export default Calendar