import React from "react"
import bootstrap from "../components/bootstrap.module.css"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import Tags from "../components/tags.js"
import Excerpt from "../components/excerpt.js"
import FormatDate from "../utils/format-date.js"
import PostThumbnail from "../components/thumbnail";

const TileBig = ({ node, className, id }) => {
    
    return (
        <div key={id} className={['card',bootstrap.h100,bootstrap.dFlex,bootstrap.flexColumn,className].join(' ')}> 
            <AniLink fade to={`/${node.post_name}/`} className={bootstrap.dBlock+' '+bootstrap.mb3 + ' ' + bootstrap.flexFill}><PostThumbnail image={node.mysqlImage} className={bootstrap.h100} /></AniLink>
            <div className={[bootstrap.px4,bootstrap.pt2,bootstrap.pb4].join(' ')}>         
            <div className={bootstrap.small + ' meta ' + bootstrap.mb2}>{FormatDate(node.post_date)}</div>
            <AniLink fade to={`/${node.post_name}/`} className={bootstrap.mb1 + ' h3 ' + bootstrap.h3 + ' ' + bootstrap.dBlock}>{node.post_title}</AniLink>
            <Excerpt node={node} className={[bootstrap.py2,bootstrap.textMuted].join(' ')} />
            <Tags tags={node.postterms} />
            </div>
        </div>
    )
  }

  
  export default TileBig