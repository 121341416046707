import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import bootstrap from "../components/bootstrap.module.css"
import FormatDate from "../utils/format-date.js"
import Tags from "../components/tags.js"
import Newsletter from "../components/newsletter.js"
import PostThumbnail from "../components/thumbnail";
import AniLink from "gatsby-plugin-transition-link/AniLink"
import TileBig from "../components/tile-big"
import Tile from "../components/tile"
import TileSide from "../components/tile-side"
import Calendar from "../components/calendar"
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import SummaryYear from '../components/summary-year.js'

const IndexPage = ({data}) => {
  
const mainFirst = data.mainFirst.nodes
const mainSecond = data.mainSecond.nodes
const mainThird = data.mainThird.nodes

const postNews = data.news.edges
const postFintech = data.fintechNews.edges
const postFintechLegal = data.fintechLegal.edges
const postEditorial = data.editorials.edges
const events = data.events.edges
const eventsFiltered = events.filter( ({node}, i) => Date.parse(node.date)+24*60*60*1000 >= (new Date()).getTime() )
let i = 0, j = 0, k = 0, l = 0 

const podcasts = data.podcasts.edges
const podcastsPosts = data.podcastsPosts.edges

const summaryYear = data.summaryYear.nodes;

const breakpoints = useBreakpoint();

const exclude = []

const bottom = (<>
{summaryYear.length > 0 && <SummaryYear posts={summaryYear} />}

<div className={bootstrap.mb5 + ' ' + bootstrap.dFlex}>
  <div className={[bootstrap.dNone,bootstrap.alignItemsCenter,bootstrap.dMdFlex,bootstrap.flexColumn,'gap3','line-row'].join(' ')}>
    <img src="/assets/ico-editorial.svg" alt="Ikona artykuł" />
    <div className={`line`}></div>
  </div>
  <div className={[bootstrap.flexFill].join(' ')}>
    <div className={[bootstrap.row].join(' ')}>
      <div className={[bootstrap.mb4 + ' ' + bootstrap.colMd9].join(' ')}>   
          <p className={[bootstrap.h2,'fw-500',bootstrap.mb3].join(' ')}>FinTech</p>
          <p className={bootstrap.mb2}>Sektor FinTech tworzą wszystkie firmy wykorzystujące najnowocześniejsze technologie do świadczenia usług finansowych przez Internet. Do fintechów zalicza się banki, kantory internetowe, instytucje płatnicze, instytucje pożyczkowe online.</p>
      </div>
      <div className={[bootstrap.mb4 + ' ' + bootstrap.colXl9].join(' ')}>
          <div className={bootstrap.row}>
            {postFintech.slice(0,1).map(({ node }, id) => (
            <div className={[bootstrap.colXl8,bootstrap.mb4].join(' ')}>
              <TileBig node={node} id={id} />
            </div>
            ))} 
            {postFintech.slice(1).map(({ node }, id) => (exclude.indexOf(node.post_name) === -1 && ++i > 0 && i < 7 &&
            <div className={[i <= 4 ? bootstrap.colXl4:bootstrap.col12,bootstrap.mb4].join(' ')}>
              {i <= 4 ? <Tile node={node} id={id} hideThumbnail={i > 3} hideExcerpt={i > 1} /> : <TileSide node={node} id={id} />}
            </div>
            ))} 
          </div>
          <AniLink cover to="/aktualnosci/fintech/" className={'btn-more '+bootstrap.mt2}>Więcej artykułów</AniLink>
      </div>
      <div className={[bootstrap.colXl3, bootstrap.order0].join(' ')}>  
        <div className={['card',bootstrap.mb3,bootstrap.p4].join(' ')}>
          {postNews.slice(9,16).map(({ node }, id) => (exclude.indexOf(node.post_name) === -1 &&
            <div key={id}>
              <AniLink fade to={`/${node.post_name}/`}>
                <span className={[bootstrap.dBlock,bootstrap.textMuted,bootstrap.textUppercase,bootstrap.small].join(' ')}>{FormatDate(node.post_date)}</span>
                <strong className={[bootstrap.dBlock,bootstrap.textDark,bootstrap.mb3].join(' ')}>{node.post_title}</strong>
              </AniLink>
              {k++ <= 5 && <hr />}
            </div>	
          ))}  

        </div>
        <div className={['card', bootstrap.p4].join(' ')}>
          {postEditorial.slice(0,4).map(({ node }, id) => (exclude.indexOf(node.post_name) === -1 &&
            <div key={id}>
              <AniLink fade to={`/${node.post_name}/`} className={[bootstrap.dFlex,'gap3'].join(' ')}>
                <PostThumbnail image={node.author.mysqlImage} className={`editorial-image`} />
                <div>
                  <span className={[bootstrap.dBlock,bootstrap.textMuted,bootstrap.textUppercase,bootstrap.small].join(' ')}>{node.author.display_name}</span>
                  <strong className={[bootstrap.dBlock,bootstrap.textDark,bootstrap.mb1].join(' ')}>{node.post_title}</strong>
                </div>
              </AniLink>
              {l++ <= 2 && <hr />}
            </div>	
          ))}  
        </div>              
      </div>
    </div>
  </div>
</div>

<div className={bootstrap.mb5 + ' ' + bootstrap.dFlex}>
  <div className={[bootstrap.dNone,bootstrap.alignItemsCenter,bootstrap.dMdFlex,bootstrap.flexColumn,'gap3','line-row'].join(' ')}>
    <img src="/assets/ico-editorial.svg" alt="Ikona artykuł" />
    <div className={`line`}></div>
  </div>
  <div className={[bootstrap.flexFill].join(' ')}>
    <div className={[bootstrap.row].join(' ')}>
      <div className={[bootstrap.mb4 + ' ' + bootstrap.colMd9].join(' ')}>   
          <p className={[bootstrap.h2,'fw-500',bootstrap.mb3].join(' ')}>Legal</p>
          <p className={bootstrap.mb2}>Sektor FinTech tworzą wszystkie firmy wykorzystujące najnowocześniejsze technologie do świadczenia usług finansowych przez Internet. Do fintechów zalicza się banki, kantory internetowe, instytucje płatnicze, instytucje pożyczkowe online.</p>
      </div>
      <div className={[bootstrap.mb4 + ' ' + bootstrap.colXl9].join(' ')}>
          <div className={bootstrap.row}>
            {postFintechLegal.slice(0,1).map(({ node }, id) => (
            <div className={[bootstrap.colXl8,bootstrap.mb4].join(' ')}>
              <TileBig node={node} id={id} />
            </div>
            ))} 
            {postFintechLegal.slice(1).map(({ node }, id) => (exclude.indexOf(node.post_name) === -1 && ++j > 0 && j < 6 &&
            <div className={[j <= 4 ? bootstrap.colXl4:bootstrap.col12,bootstrap.mb4].join(' ')}>
              {j <= 4 ? <Tile node={node} id={id} hideThumbnail={j > 3} hideExcerpt={j > 1} /> : <TileSide node={node} id={id} />}
            </div>
            ))} 
          </div>
          <AniLink cover to="/legal/" className={'btn-more '+bootstrap.mt2}>Więcej artykułów</AniLink>
      </div>
      <div className={[bootstrap.mb4, bootstrap.colXl3, bootstrap.order0].join(' ')}>  
      <div className={['card',bootstrap.mb3,bootstrap.p4].join(' ')}>
          {postNews.slice(16,22).map(({ node }, id) => (exclude.indexOf(node.post_name) === -1 &&
            <div key={id}>
              <AniLink fade to={`/${node.post_name}/`}>
                <span className={[bootstrap.dBlock,bootstrap.textMuted,bootstrap.textUppercase,bootstrap.small].join(' ')}>{FormatDate(node.post_date)}</span>
                <strong className={[bootstrap.dBlock,bootstrap.textDark,bootstrap.mb3].join(' ')}>{node.post_title}</strong>
              </AniLink>
              {k++ <= 11 && <hr />}
            </div>	
          ))}  
        </div>
    
      </div>
    </div>
  </div>
</div> 



<div className={[bootstrap.dFlex,bootstrap.mb5].join(' ')}>
  <div className={[bootstrap.dNone,bootstrap.alignItemsCenter,bootstrap.dMdFlex,bootstrap.flexColumn,'gap3','line-row'].join(' ')}>
    <img src="/assets/ico-calendar.svg" alt="Ikona kalendarz" />
    <div className={`line`}></div>
  </div>
  <div className={[bootstrap.flexFill].join(' ')}>

    <p className={[bootstrap.h2,'fw-500',bootstrap.mb3].join(' ')}>Eventy</p>
    <p className={bootstrap.mb5}>Najważniejsze konferencje z branży FinTech. W tej sekcji znajdziecie zarówno duże eventy, jak i branżowe webinary. To świetna okazja do networkingu i zapoznania się z głównymi trendami w sektorze FinTech.</p>
  

    <div className={[bootstrap.row].join(' ')}>
      <div className={[bootstrap.mb4 + ' ' + bootstrap.colXl9].join(' ')}>   
            <p className={[bootstrap.h4,'h4',bootstrap.mb3].join(' ')}>{new Date().toLocaleString('default', {month: 'long', year: 'numeric'})}</p>
            <div className={['card',bootstrap.mb3,bootstrap.p4].join(' ')}>
              <Calendar events={events} />
            </div>
            <AniLink cover to="/wydarzenia/" className={'btn-more '+bootstrap.mt2}>Wszystkie wydarzenia</AniLink>
      </div>

      <div className={[bootstrap.mb4 + ' ' + bootstrap.colXl3].join(' ')}>  
          <p className={[bootstrap.h4,'h4',bootstrap.mb3].join(' ')}>Lista eventów</p>
          <div className={['card',bootstrap.mb3,bootstrap.p4].join(' ')}>
            {eventsFiltered.map(({ node }, j) => (
              <div key={`${i}-${j}`}>
                <AniLink fade to={`/wydarzenie/${node.post_name}/`}>
                  <span className={[bootstrap.dBlock,bootstrap.textMuted,bootstrap.textUppercase].join(' ')}>{FormatDate(node.date, false)}</span>
                  <strong className={[bootstrap.dBlock,bootstrap.textUppercase,bootstrap.small].join(' ')}>{node.city}</strong>
                  <strong className={[bootstrap.dBlock,bootstrap.textDark,bootstrap.mb3].join(' ')}>{node.post_title}</strong>
                </AniLink>
                {j < eventsFiltered.length-1 && <hr />}
              </div>	
            ))}  
          </div>
          
      </div>
    </div>


  </div>

</div>  

<Newsletter />

<div className={[bootstrap.dFlex].join(' ')}>
  <div className={[bootstrap.dNone,bootstrap.alignItemsCenter,bootstrap.dMdFlex,bootstrap.flexColumn,'gap3','line-row'].join(' ')}>
    <img src="/assets/ico-podcasts.svg" alt="Ikona podkasty" />
    <div className={`line`}></div>
  </div>
  <div className={[bootstrap.flexFill].join(' ')}>

    <p className={[bootstrap.h2,'fw-500',bootstrap.mb3].join(' ')}>Podcasty</p>
    <p className={bootstrap.mb5}>Najnowsze wiadomości i artykułu ze świata FinTech, bankowości, InsurTech, płatności oraz technologii mobilnych. Najświeższe newsy o narzędziach i usługach, które ułatwiają życie. Prawo, biznes i ekonomia.</p>
  

    <div className={[bootstrap.row].join(' ')}>
      <div className={[bootstrap.mb4 + ' ' + bootstrap.colXl9].join(' ')}>   
            <p className={[bootstrap.h4,'h4',bootstrap.mb3].join(' ')}>Przeglądaj podcasty wg tematów</p>

            {podcasts.map(({ node }, id) => ( 
              <div key={`${id}`} className={[bootstrap.mb4,'card',bootstrap.dFlex,bootstrap.flexColumn,bootstrap.flexMdRow].join(' ')}>

                  <PostThumbnail image={node.mysqlImage} className={[bootstrap.colMd4,bootstrap.p0].join(' ')} />

                  <div className={[bootstrap.p4].join(' ')}>
                    <AniLink className={[bootstrap.h3,'h3',bootstrap.mb2,bootstrap.dBlock].join(' ')} fade to={(node.parent_slug ? `/${node.parent_slug}` : '')+`/${node.slug}/`}>#{node.name}</AniLink>
                    <p>{node.description || `Zobacz podcasty z kategorii` + node.name}</p>

                    {podcastsPosts.filter(({ node: postNode }) => postNode.postterms.filter((el) => { return el.slug === node.slug} ).length > 0  ).slice(0,1).map(({ node }, id) => (
                      <div key={id} className={['border',bootstrap.mb3,bootstrap.dFlex].join(' ')}>
                        <div className={['bg-primary-gradient',bootstrap.dFlex,bootstrap.alignItemsCenter,bootstrap.px3,bootstrap.textWhite].join(' ')}>▶</div>
                        <AniLink fade to={`/${node.post_name}/`} className={[bootstrap.px3,bootstrap.py2].join(' ')}>
                          <strong className={[bootstrap.dBlock,bootstrap.textUppercase,bootstrap.textDark,bootstrap.small].join(' ')}>Ostatni odcinek</strong>
                          <span className={[bootstrap.dBlock,bootstrap.textDark].join(' ')}>{node.post_title}</span>
                        </AniLink>
                      </div>	
                    ))}  

                    <AniLink cover to={(node.parent_slug ? `/${node.parent_slug}` : '')+`/${node.slug}/`} className={'btn-more '+bootstrap.mt2}>Wszystkie podcasty #{node.name}</AniLink>
                  </div>
              </div>	
            ))}  

            <AniLink cover to="/podcasty/" className={'btn-more '+bootstrap.mt2}>Wszystkie podcasty</AniLink>
      </div>

      <div className={[bootstrap.mb4 + ' ' + bootstrap.colXl3].join(' ')}>  
          <p className={[bootstrap.h4,'h4',bootstrap.mb4].join(' ')}>Ostatnie podcasty</p>

          <div className={['card',bootstrap.mb3,bootstrap.p4].join(' ')}>
            {podcastsPosts.slice(0,8).map(({ node }, id) => (
              <div key={id} className={['border',bootstrap.mb3,bootstrap.dFlex].join(' ')}>
                <div className={['bg-primary-light',bootstrap.dFlex,bootstrap.alignItemsCenter,bootstrap.px2,'text-primary'].join(' ')}>▶</div>
                <AniLink fade to={`/${node.post_name}/`} className={[bootstrap.px3,bootstrap.py2].join(' ')}>
                  <Tags tags={node.postterms.filter((el) => el.parent_slug === 'podcasty-sfintechowani')} />
                  <span className={[bootstrap.dBlock,bootstrap.textDark].join(' ')}>{node.post_title}</span>
                </AniLink>
              </div>	
            ))}  
          </div>

      </div>
    </div>


  </div>

</div>  



</>)

i = 0;

return (
  <Layout isHome={true} bottom={bottom}>
    <SEO title="Think Tech" canonical="https://fintek.pl/" />

    <div className={[bootstrap.dFlex].join(' ')}>
      <div className={[bootstrap.dNone,bootstrap.alignItemsCenter,bootstrap.dMdFlex,bootstrap.flexColumn,bootstrap.py5,'gap3','line-row'].join(' ')}>
        <div className={`line`}></div>
      </div>

      <div className={[bootstrap.flexFill].join(' ')}>
        <div className={[bootstrap.row].join(' ')}> 
          <div className={[bootstrap.colXl8,bootstrap.mb4].join(' ')}>         
            {mainFirst.map((node, id) => { exclude.push(node.post_name); return (
              <TileBig node={node} id={id} />
            )})}             
          </div>

          <div className={[[bootstrap.colXl4,bootstrap.dFlex,bootstrap.flexColumn,'gap3',bootstrap.mb4].join(' ')]}>         
            {mainSecond.map((node, id) =>  { exclude.push(node.post_name); return (
              <Tile node={node} id={id} hideThumbnail={true} className={bootstrap.mb3} />
            )})}
            {mainThird.map((node, id) =>  { exclude.push(node.post_name); return (
              <Tile node={node} id={id} hideThumbnail={true} hideExcerpt={true} />
            )})}                       
          </div>
        </div>

        {breakpoints.sm ? <>
          <div className={bootstrap.row}>
            {postNews.filter(({ node }) => !exclude.includes(node.post_name)).slice(0, 2).map(({ node }, id) => (
              <div key={id} className={[bootstrap.col12, bootstrap.mb4].join(' ')}>
                <Tile node={node} id={id} hideThumbnail={true} hideExcerpt={true} />
              </div>
            ))} 
          </div>

          <div className={['card', bootstrap.mb4, bootstrap.p4].join(' ')}>
            <strong className={[bootstrap.textUppercase,bootstrap.dBlock,bootstrap.mb3].join(' ')}>
              <img src="/assets/ico-podcasts-small.svg" alt="Ikona podcasty" className={bootstrap.mr2} />
              Ostatnie podcasty
            </strong>

            {podcastsPosts.slice(0, 3).map(({ node }, id) => (
              <div key={id} className={['border',bootstrap.mb3,bootstrap.dFlex].join(' ')}>
                <div className={['bg-primary-light',bootstrap.dFlex,bootstrap.alignItemsCenter,bootstrap.px2,'text-primary'].join(' ')}>▶</div>
                <AniLink fade to={`/${node.post_name}/`} className={[bootstrap.px3,bootstrap.py2].join(' ')}>
                  <Tags tags={node.postterms.filter((el) => el.parent_slug === 'podcasty')} />
                  <span className={[bootstrap.dBlock,bootstrap.textDark].join(' ')}>{node.post_title}</span>
                </AniLink>
              </div>
            ))}
            <AniLink cover to="/podcasty/" className={['btn-primary-gradient',bootstrap.btn,bootstrap.btnBlock,bootstrap.py2].join(' ')}>Podcasty</AniLink>
          </div>

          <div className={bootstrap.row}>
            {postNews.filter(({ node }) => !exclude.includes(node.post_name)).slice(2).map(({ node }, id) => ++i && (
              <div key={id} className={[i <= 6?bootstrap.colXl4:bootstrap.col12,bootstrap.mb4].join(' ')}>
                {i <= 6 ? <Tile node={node} id={id} hideThumbnail={i > 3} hideExcerpt={true} /> : <TileSide node={node} id={id} />}
              </div>
            ))} 
          </div>
        </> : <>
          <div className={bootstrap.row}>
            {postNews.map(({ node }, id) => (exclude.indexOf(node.post_name) === -1 && ++i <= 9 &&
              <div className={[i <= 6?bootstrap.colXl4:bootstrap.col12,bootstrap.mb4].join(' ')}>
                {i <= 6 ? <Tile node={node} id={id} hideThumbnail={i > 3} hideExcerpt={true} /> : <TileSide node={node} id={id} />}
              </div>
            ))} 
          </div>

          <AniLink cover to="/aktualnosci/" className={'btn-more '+bootstrap.mt2}>Więcej aktualności</AniLink>
        </>}
      </div>
    </div>
  </Layout>
)}

export default IndexPage

export const query = graphql`
  fragment MainPost on MysqlPost {
    post_name
    post_type
    post_title
    post_excerpt
    post_date
    lead
    postmetas {
      meta_key
      meta_value
    }
    mysqlImage {
    childImageSharp {
        fluid(maxWidth: 520, maxHeight: 380, cropFocus: CENTER) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    postterms {
      name
      slug
      parent_slug
    }   
  }

  query {
    mainFirst: allMysqlPost(limit: 1, sort: {fields: post_date, order: DESC}, filter: {postmetas: {elemMatch: {meta_key: {eq: "wpcf-top-glowna"}, meta_value: { eq: "1", nin: ["2", "3"] } }}} ) {
      nodes {
        ...MainPost      
      }
    }
    
    mainSecond: allMysqlPost(limit: 1, sort: {fields: post_date, order: DESC}, filter: {postmetas: {elemMatch: {meta_key: {eq: "wpcf-top-glowna"}, meta_value: { eq: "2" } }}} ) {
      nodes {
        ...MainPost      
      }
    }
    
    mainThird: allMysqlPost(limit: 1, sort: {fields: post_date, order: DESC}, filter: {postmetas: {elemMatch: {meta_key: {eq: "wpcf-top-glowna"}, meta_value: { eq: "3" } }}} ) {
      nodes {
        ...MainPost      
      }
    }

    news: allMysqlPost(
      limit: 25, 
      sort: {fields: post_date, order: DESC}, 
      filter: {postterms: {elemMatch: {slug: {eq: "aktualnosci", ne: "podsumowania-i-prognozy-2024"}}} }
    ) {
      edges {
        node {
          post_name
          post_type
          post_title
          post_excerpt
          post_date
          lead
          mysqlImage {
        	childImageSharp {
              fluid(maxWidth: 500, maxHeight: 250, cropFocus: CENTER) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          postterms {
            name
            slug
            parent_slug
          }          
        }            
      }
    }
    fintechNews: allMysqlPost(
      limit: 20, 
      sort: {fields: post_date, order: DESC}, 
      filter: {postterms: {elemMatch: {slug: {eq: "fintech", ne: "podsumowania-i-prognozy-2024"}}}}
    ) {
      edges {
        node {
          post_name
          post_type
          post_title
          post_excerpt
          post_date
          lead
          mysqlImage {
        	childImageSharp {
              fluid(maxWidth: 400, maxHeight: 200, cropFocus: CENTER) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          postterms {
            name
            slug
            parent_slug
          }          
        }            
      }
    }
    editorials: allMysqlPost(
      limit: 6, 
      sort: {fields: post_date, order: DESC}, 
      filter: {postterms: {elemMatch: {slug: {eq: "felietony", ne: "podsumowania-i-prognozy-2024"}}}}
    ) {
      edges {
        node {
          post_name
          post_type
          post_title
          post_excerpt
          post_date
          lead
          postterms {
            name
            slug
            parent_slug
          }      
          author {
            display_name
            mysqlImage {
              childImageSharp {
                  fluid(maxWidth: 100, maxHeight: 100, cropFocus: CENTER) {
                    ...GatsbyImageSharpFluid_withWebp 
                  }
                }
              }
          }              
        }            
      }
    }
    fintechLegal: allMysqlPost(
      limit: 8, 
      sort: {fields: post_date, order: DESC}, 
      filter: {postterms: {elemMatch: {slug: {eq: "legal", ne: "podsumowania-i-prognozy-2024"}}}}
    ) {
      edges {
        node {
          post_name
          post_type
          post_title
          post_excerpt
          post_date
          lead
          mysqlImage {
        	childImageSharp {
              fluid(maxWidth: 400, maxHeight: 200, cropFocus: CENTER) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          postterms {
            name
            slug
            parent_slug
          }          
        }            
      }
    }
    events: allMysqlEvent(limit: 100, sort: {order: DESC, fields: date}) {
      totalCount
      edges {
        node {
          post_name
          post_title
          post_excerpt
          date
          city
        }
      }
    }   
    podcasts: allMysqlTerm(filter: {parent_slug: {eq: "podcasty"}}) {
      edges {
        node {
          mysqlId
          name
          slug
          parent_slug
          description
          mysqlImage {
            childImageSharp {
                fluid(maxWidth: 400, maxHeight: 400, cropFocus: CENTER) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }          
        }
      }
    }
    podcastsPosts: allMysqlPost(limit: 100, sort: {fields: post_date, order: DESC}, filter: {postterms: {elemMatch: {parent_slug: {eq: "podcasty"}, post: {}}}}) {
      edges {
        node {
          post_name
          post_type
          post_title
          post_excerpt
          post_date
          lead
          mysqlImage {
          childImageSharp {
              fluid(maxWidth: 230, maxHeight: 230, cropFocus: CENTER) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          postterms {
            name
            slug
            parent_slug
          }   
        }       
      }
    }
    summaryYear: allMysqlPost(
      limit: 8, 
      sort: {fields: post_date, order: DESC}, 
      filter: {postterms: {elemMatch: {slug: {eq: "sztuczna-inteligencja-i-regulowanie-innowacji"}}}}
    ) {
      nodes {
        post_name
        post_title
        post_excerpt
        post_date
        postterms {
          name
          slug
          parent_slug
        }
        author {
          user_nicename
          display_name
          mysqlImage {
            childImageSharp {
              fluid(maxWidth: 30, maxHeight: 30) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        mysqlImage {
        	childImageSharp {
            fluid(maxWidth: 400, maxHeight: 250, cropFocus: CENTER) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`
